@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    font-family: "Roboto", sans-serif;
    scroll-behavior: smooth;
}

.heading-ring,
.hero-ring {
  position: absolute;
  border: 3px solid #fec73799;
  border-radius: 300px;
  width: 150px;
  height: 60px;
  right: -10px;
  top: 70%;
  transform: translateY(-50%) rotate(-15deg);
}

.hero-ring {
  width: 346px;
  height: 79px;
  right: 30px;
  top: 40%;
  transform: translateY(-50%) rotate(-8.15deg);
}
.downloads-hero-ring {
  width: 200px;
  height: 79px;
  right: 41px;
  top: 25%;
  transform: translateY(-50%) rotate(-8.85deg);
}

.active {
  color: #3e5fff !important;
}

.shadow {
  box-shadow: 20px 20px 100px #3754aa0f !important;
}

.profile-shadow {
  box-shadow: 0px 8px 16px #00000021 !important;
}

.pricing-shadow {
  box-shadow: 10px 10px 60px #00000012 !important;
}

@media screen and (min-width: 1500px) {
  .hero-ring {
    right: 10px;
    top: 40%;
  }
}